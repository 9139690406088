import React from "react";

const sansSerifFamily = "Arial, sans-serif";

export function styles(submitDisabled, showScreenKey) {
  return {
    parent: {
      backgroundColor: "rgba(51, 51, 51, 0.9)",
      color: "#ffffff",
      margin: "2vh",
      padding: "24px 48px",
      fontFamily: "Lato",
      textAlign: "center"
    } as React.CSSProperties,
    container: {
      display: "flex",
      flexDirection: "column"
    } as React.CSSProperties,
    label: {
      display: "block",
      fontSize: "1.375rem",
      textAlign: "center",
      marginBottom: "1rem"
    } as React.CSSProperties,
    input: {
      display: "block",
      width: "100%",
      textAlign: "center",
      borderRadius: "4px",
      height: "2.8rem",
      border: "none",
      fontSize: "1.625rem",
      lineHeight: "1.625rem"
    } as React.CSSProperties,
    screenKey: {
      width: "53px",
      borderRadius: "2px",
      border: "none"
    } as React.CSSProperties,
    labelText: {
      marginBottom: "1rem",
      fontFamily: "Lato",
      fontWeight: "normal"
    } as React.CSSProperties,
    screenKeyInvalid: {
      width: "53px",
      borderRadius: "2px",
      margin: "8px",
      border: "red solid 1px"
    } as React.CSSProperties,
    button: {
      whiteSpace: "nowrap",
      display: "inline-block",
      textAlign: "center",
      borderRadius: "4px",
      backgroundColor: submitDisabled ? "#979797" : "#66CC33",
      fontSize: "1.5rem",
      lineHeight: "1.8125rem",
      textDecoration: "none",
      padding: "0.4375rem 1.0625rem",
      color: submitDisabled ? "#696969" : "#333333",
      border: "none",
      marginTop: "8px",
      cursor: "pointer"
    } as React.CSSProperties,
    a: {
      whiteSpace: "nowrap",
      display: "inline-block",
      textAlign: "center",
      margin: "1vh auto",
      padding: "0 2vh",
      borderRadius: "0.5vh",
      backgroundColor: "#66cc33",
      fontSize: "3vh",
      textDecoration: "none",
      color: "#ffffff"
    } as React.CSSProperties,
    error: {
      fontStyle: "italic",
      fontWeight: 200,
      lineHeight: "inherit",
      margin: "0.5rem 0 1.5rem 0",
      fontSize: "1.375rem",
      display: "block",
      color: "red"
    } as React.CSSProperties,
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      font: sansSerifFamily
    } as React.CSSProperties,
    www: {
      fontSize: "1.375rem",
      fontWeight: "normal"
    } as React.CSSProperties,
    close: {
      fontSize: "1.6875rem",
      fontStyle: "italic",
      margin: "1.5rem 0",
      fontFamily: sansSerifFamily,
      fontWeight: 200
    } as React.CSSProperties,
    feedbackLink: {
      color: "#66CC33",
      fontSize: "1.125rem"
    } as React.CSSProperties,
    connecting: {
      color: "#66CC33",
      fontSize: "3.5rem",
      padding: "0 1.9375rem"
    } as React.CSSProperties,
    connectingImage: {
      marginTop: "1.5rem"
    } as React.CSSProperties
  };
}
