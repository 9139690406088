import React from "react";

export const IconTextHolder = props => {
  const style = {
    parent: {
      display: "flex"
    },
    icon: {
      color: "#66cc33",
      minWidth: "7vh",
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      alignItems: "center"
    } as React.CSSProperties,
    text: {
      flex: "1",
      display: "flex",
      flexDirection: "column",
      alignSelf: "center"
    } as React.CSSProperties
  };

  return (
    <div style={style.parent}>
      {React.Children.map(props.children, (child, index) => (
        <div style={index === 0 ? style.icon : style.text}>{child}</div>
      ))}
    </div>
  );
};

export default IconTextHolder;
