import React from "react";
import IconTextHolder from "../Layout/IconTextHolder";
import { Trans, withTranslation } from "react-i18next";

import * as namespace from "../lang/namespace";
import "../Typography/font.css";

const InviteCaption = () => {
  const style = {
    text: {
      fontFamily: "Lato",
      fontWeight: 700,
      fontStyle: "italic",
      fontSize: "2vh"
    } as React.CSSProperties
  };

  return (
    <IconTextHolder>
      <div />
      <div style={style.text}>
        <Trans i18nKey="quickconnect__joinInPerson">
          Join in person at one of the pod locations below or connect another
          pod.
        </Trans>
      </div>
    </IconTextHolder>
  );
};

export default withTranslation(namespace)(InviteCaption);
