import { browserVersion, isChrome, isEdge, isFirefox, isMacOs, isMobile, isSafari, isWindows } from 'react-device-detect';

export function webRTCSupported(platform: Platform): boolean {
  const osFamily: string = platform.os.family.toLowerCase();

  if (isMobile) {
    return false;
  } else if (isWindows) {
    return isFirefox || isChrome || isEdge;
  } else if (isMacOs) {
    return isFirefox || isChrome || isSafari13();
  } else if (isLinuxOS(osFamily)) {
    return isFirefox || isChrome;
  } else if (isChromeOS(osFamily)) {
    return isFirefox || isChrome;
  } else {
    return false;
  }
}

function isSafari13(): boolean {
  // Safari has a bug that sends an SNI even if the hostname is just the IP address (which is against spec). Therefore
  // the rustls component of webrtc server rejects the connection. Setting a hostname for the pod and accessing it via the
  // hostname instead of its ip address allows webrtc to work
  var ipRegex = new RegExp('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)');
  return isSafari && parseInt(browserVersion) >= 13 && !ipRegex.test(window.location.hostname);
}

export function isWindows32OS(osFamily: string, architecture: number): boolean {
  return isWindows && architecture == 32;
}

export function isLinuxOS(os: string): boolean {
  const linuxRegex = /(linux|ubuntu|fedora|gentoo|debian|red hat|kubuntu)/i;
  return linuxRegex.test(os);
}

export function isChromeOS(os: string) {
  const chromeOSRegex = /chrome os/i;
  return chromeOSRegex.test(os);
}

export function clientIsSupported(os: string) {
  return !(isLinuxOS(os) || isChromeOS(os));
}

export function isMobileOS() {
  return isMobile;
}
