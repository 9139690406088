import React from "react";

import InviteCaption from "./InviteCaption";
import AudioBridgeContainer from "../Containers/AudioBridgeContainer";
import PodHolderContainer from "../Containers/PodHolderContainer";
import "../Typography/font.css";

const InviteHolder = props => {
  const { isInvitePage, sessionEnded } = props;

  const style = {
    parent: {
      color: "#333333",
      width: "100%",
      marginTop: "3vh",
      marginBottom: "3vh"
    },
    box: {
      flex: 1,
      display: "flex",
      flexDirection: "column"
    } as React.CSSProperties
  };

  return (
    <div style={style.parent}>
      {isInvitePage && !sessionEnded ? (
        <div style={style.box}>
          <InviteCaption />
          <PodHolderContainer />
          <AudioBridgeContainer />
        </div>
      ) : null}
    </div>
  );
};

export default InviteHolder;
