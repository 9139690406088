import React from "react";
import platform from "platform";
import { Trans, withTranslation } from "react-i18next";
import { redirectToWebRTC } from "../Functions/redirects";
import { isWindows32OS, isMobileOS } from "../Functions/webRTCSupported";
import * as namespace from "../lang/namespace";
import "../Typography/font.css";

export function CallToAction(props) {
  const {
    link,
    sessionEnded,
    hasApp,
    isMultiRoom,
    clientDownloaded, // action
    isWebRTCSupported,
    isClientSupported
  } = props;

  const style = {
    a: {
      whiteSpace: "nowrap",
      display: "inline-block",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: "40px",
      marginBottom: "64px",
      paddingLeft: "2vh",
      paddingRight: "2vh",
      minWidth: '300px',
      paddingTop: "1vh",
      paddingBottom: "1vh",
      borderRadius: "0.5vh",
      backgroundColor: "#66cc33",
      fontSize: "21px",
      fontWeight: 600,
      textDecoration: "none",
      color: "#333333",
      cursor: "pointer"
    } as React.CSSProperties,
    aNoApp: {
      whiteSpace: "nowrap",
      display: "inline",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      marginBottom: "64px",
      paddingLeft: "2vh",
      paddingRight: "2vh",
      minWidth: '300px',
      paddingTop: "1vh",
      paddingBottom: "1vh",
      borderRadius: "0.5vh",
      backgroundColor: "#66cc33",
      fontSize: "21px",
      fontWeight: 600,
      textDecoration: "none",
      color: "#333333",
      cursor: "pointer"
    } as React.CSSProperties,
    aNoAppNoWebRTCHidden: {
      whiteSpace: "nowrap",
      display: isWebRTCSupported ? "inline" : "none" ,
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      marginBottom: "64px",
      paddingLeft: "2vh",
      paddingRight: "2vh",
      minWidth: '300px',
      paddingTop: "1vh",
      paddingBottom: "1vh",
      borderRadius: "0.5vh",
      backgroundColor: "#66cc33",
      fontSize: "21px",
      fontWeight: 600,
      textDecoration: "none",
      color: "#333333",
      cursor: "pointer"
    } as React.CSSProperties,
    aNoAppInverted: {
      whiteSpace: "nowrap",
      display: "inline-block",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      marginBottom: "64px",
      paddingLeft: "2vh",
      paddingRight: "2vh",
      minWidth: '300px',
      paddingTop: "1vh",
      paddingBottom: "1vh",
      borderRadius: "0.5vh",
      borderStyle: "solid",
      borderColor: "#66cc33",
      backgroundColor: "#333333",
      fontSize: "21px",
      fontWeight: 600,
      textDecoration: "none",
      color: "#66cc33",
      cursor: "pointer"
    } as React.CSSProperties,
    trans: {
      whiteSpace: "initial"
    },
    noApp: {
      marginTop: '40px',
      marginBottom: '40px',
      textAlign: 'center',
      fontSize: '20px',
      width: '100%',
      fontWeight: 300,
    } as React.CSSProperties
  };

  const isMobile = isMobileOS();
  const isWindows32 = isWindows32OS(platform.os.family, platform.os.architecture);
  const mersiveDownloadLink = "https://www.mersive.com/download/";

  let win32Part2;
  if (isWindows32 && isWebRTCSupported) {
    win32Part2 = (
      <Trans i18nKey="quickconnect__32windowsNotSupportedPart2">
        Alternatively, you can share your web browser (click on Launch in Browser).
      </Trans>
    );
  }

  let win32Warning;
  if (isWindows32) {
    win32Warning =
      <div style={style.noApp}>
        <Trans i18nKey="quickconnect__32windowsNotSupportedPart1">
          The Solstice Client requires a 64-bit version of Windows to run. Please use https://www.mersive.com/download to obtain the 32-bit clients.
        </Trans>
        <span>&nbsp;</span>
        { win32Part2}
      </div>
    ;
  }

  const selectElement = function() {
    if (isWebRTCSupported && (!isClientSupported || isWindows32)) {
      return (
        <>
          { win32Warning }
          <a
            style={style.a}
            href={"javascript:void(0)"}
            onClick={() => redirectToWebRTC(window.location)}
          >
            <Trans i18nKey="quickconnect__CTAwebRTCEnabledLaunchInBrowser">
              Launch in Browser
            </Trans>
          </a>
        </>
      );
    }
    else if (!isWebRTCSupported && (!isClientSupported || isWindows32)) {
      return (
        <>
          { win32Warning }
        </>
      );
    }

  if (hasApp && isMultiRoom)
        return (
          <a
            style={style.a}
            href={link}
            onClick={() => setTimeout(clientDownloaded, 0)}
          >
            <Trans i18nKey="quickconnect__CTAsessionActiveHasAppIsMultiRoom">
              Launch &amp; Join
            </Trans>
          </a>
        );
      else if (hasApp && !isMultiRoom)
        return (
          <a
            style={style.a}
            href={link}
            onClick={() => setTimeout(clientDownloaded, 0)}
          >
            <Trans i18nKey="quickconnect__CTAsessionActiveHasAppNotMultiRoom">
              Launch &amp; Connect
            </Trans>
          </a>
        );
      else if (!hasApp && isMultiRoom)
        return (
          <>
          <div style={style.noApp}>
            <Trans i18nKey="quickconnect__useClientToConnect">
              Use Solstice to connect to the room display
            </Trans>
          </div>
            <a
              style={style.aNoAppNoWebRTCHidden}
              href={"javascript:void(0)"}
              onClick={() => redirectToWebRTC(window.location)}
            >
              <Trans i18nKey="quickconnect__shareMyScreen">
                Share my Screen
              </Trans>
            </a>
            <a
              style={ isMobile || !isWebRTCSupported ? style.aNoApp : style.aNoAppInverted}
              href={mersiveDownloadLink}
              onClick={() => setTimeout(clientDownloaded, 0)}
            >
              <Trans i18nKey="quickconnect__CTAsessionActiveNoAppIsMultiRoom1">
                Get the App
              </Trans>
            </a>
          </>
        );
      else if (!hasApp && !isMultiRoom)
        return (
          <>
            <div style={style.noApp}>
              <Trans i18nKey="quickconnect__downloadAppNotMultiRoom">
                Download the Solstice app to connect to the room display.
              </Trans>
            </div>
            <a
              style={style.aNoApp}
              href={mersiveDownloadLink}
              onClick={() => setTimeout(clientDownloaded, 0)}
            >
              <Trans i18nKey="quickconnect__CTAsessionActiveNoAppNotMultiRoom">
                Get the App &amp; Connect
              </Trans>
            </a>
          </>
        );
  };

  return selectElement();
}

export const TranslatedCallToAction = withTranslation(namespace)(CallToAction);
