// const prefix = "http://localhost:4000"; // where you're serving the API locally in development
// const prefix = 'http://192.168.3.139:4000' // pod testing in development
const prefix =
  window.location.hostname === "localhost" ? "http://localhost:4000" : ""; // no prefix required in production

export default {
  qcInitial: `${prefix}/connect/init`,
  qcPoll: `${prefix}/connect/status?identifyid=`,
  inviteInitial: `${prefix}/session/inviteJSON_`,
  invitePoll: `${prefix}/session/statusJSON_`,
  ping: `${prefix}/ping`
};
