import React from "react";
import { Collapse, Card } from "reactstrap";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Person,
  Tv
} from "@material-ui/icons";

import IconTextHolder from "../Layout/IconTextHolder";
import "../Typography/font.css";

class Pod extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      show: false
    };
  }

  toggle() {
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    const { name, numberConnected, users } = this.props.pod;

    const style = {
      row: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Lato",
        cursor: "pointer",
        fontWeight: 700
      },
      title: {
        display: "flex",
        flex: "1",
        flexDirection: "column"
      } as React.CSSProperties,
      flex: {
        flex: "1"
      },
      caption: {
        fontWeight: "400"
      },
      card: {
        fontSize: "2vh",
        border: "none",
        paddingTop: "1vh",
        paddingBottom: "1vh",
        borderBottom: "solid",
        borderRadius: "0",
        borderWidth: "1px",
        borderColor: "rgba(51, 51, 51, 0.2)"
      },
      toggle: {
        color: "#66cc33",
        display: "flex",
        alignItems: "center"
      },
      users: {
        minWidth: "0",
        flex: "1",
        fontSize: "1.5vh",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block"
      }
    };

    return (
      <Card style={style.card}>
        <IconTextHolder>
          <Tv />
          <div style={style.row} onClick={this.toggle}>
            <div style={style.title}>
              <div style={style.flex}>{name}</div>
            </div>
            <div style={style.toggle}>
              <Person style={style.flex} />
              <span style={style.flex}>{numberConnected}</span>
              {this.state.show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
          </div>
        </IconTextHolder>
        <Collapse isOpen={this.state.show}>
          <IconTextHolder>
            <div />
            <div style={style.flex}>
              <span style={style.users}>{users}</span>
            </div>
          </IconTextHolder>
        </Collapse>
      </Card>
    );
  }
}

export default Pod;
