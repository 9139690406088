import { connect } from "react-redux";
import * as _ from "lodash";

import PodHolder from "../Elements/PodHolder";

const mapStateToProps = state => {
  const result = {
    pods: _.map(state.model.SessionDisplays, pod => ({
      name: pod.SESSION_DISPLAY_NAME,
      numberConnected: pod.Users.length,
      users: _.chain(pod.Users)
        .map(pod => pod.SESSION_DISPLAY_USER_NAME)
        // .take(25) // limit to 25 names
        .join(", ")
        .trimEnd(", ")
        .concat(".")
        .value() // turn an array of users into "Name, Name, Name, Name."
    }))
  };
  return result;
};

const PodHolderContainer = connect(mapStateToProps)(PodHolder);

export default PodHolderContainer;
