import React from "react";
import { Trans, withTranslation } from "react-i18next";

import * as namespace from "../lang/namespace";
import "../Typography/font.css";

const Alternative = props => {
  const { link, hasApp } = props;

  const style = {
    color: "#66cc33",
    whiteSpace: "nowrap",
    display: "inline-block",
    textAlign: "center",
    marginRight: "auto",
    marginLeft: "auto",
    paddingLeft: "2vh",
    paddingRight: "2vh",
    fontSize: "2.5vh",
    textDecoration: "none"
  } as React.CSSProperties;

  return (
    <a style={style} href={link}>
      <div>
        {hasApp ? (
          <Trans i18nKey="quickconnect__alternativeDownloadAgain">
            Download app again
          </Trans>
        ) : (
          <Trans i18nKey="quickconnect__alternativeAlreadyHaveApp">
            Already have the app?
          </Trans>
        )}
      </div>
    </a>
  );
};

export default withTranslation(namespace)(Alternative);
