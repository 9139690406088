import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import GrayBox from "../Elements/GrayBoxConnector";
import InviteHolderContainer from "../Containers/InviteHolderContainer";
import { ClientNudge } from "../Views/ClientNudge";
import { redirectToWebRTC } from "../Functions/redirects";

const Content = props => {
  const { isInvitePage } = props;
  const style = {
    parent: isInvitePage
      ? ({
          flex: "1",
          overflowY: "auto"
        } as React.CSSProperties)
      : ({
          display: "flex",
          flex: "1",
          justifyContent: "center",
          alignItems: "center",
        } as React.CSSProperties),
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    topSpacer: {
      minHeight: "12vh"
    },
    nudgeWrapper: {
      height: "100%",
      maxWidth: "678px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    } as React.CSSProperties
  };

  const [showNudge, setShowNudge] = useState(false);

  const showNudgeHandler = () => {
    setShowNudge(true);

    document.addEventListener("click", (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const targetContainsNudge =
        target.getElementsByClassName("client-nudge").length > 0;

      if (targetContainsNudge) {
        setShowNudge(false);
        redirectToWebRTC(window.location);
      }
    });
  };

  const hideNudgeHandler = () => {
    setShowNudge(false);
  };

  return (
    <div style={style.parent}>
      {!showNudge && (
        <Container style={style.container}>
          <Row className="justify-content-center align-items-center no-gutters w-100">
            <Col className="col-xl-7 col-lg-6 col-md-8 col-sm-10 col-12 align-self-center">
              <GrayBox showNudge={showNudgeHandler} {...props} />
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center no-gutters w-100">
            <Col className="col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12 align-self-center">
              <InviteHolderContainer />
            </Col>
          </Row>
        </Container>
      )}
      {showNudge && (
        <div style={style.nudgeWrapper}>
          <ClientNudge hideNudge={hideNudgeHandler} />
        </div>
      )}
    </div>
  );
};

export default Content;
