import React from "react";
import { Trans } from "react-i18next";
import { clientDownloaded } from "../redux/action";
import { determineLink } from "../Functions/determineLink";

// CSS
import "../Typography/font.css";

import CallToActionContainer from "../Containers/CallToActionContainer";
import AlternativeContainer from "../Containers/AlternativeContainer";
import { InBrowserLaunch } from "./InBrowserLaunch";
import Header from "../Layout/Header"
import { hideWebRTCNudgeCookie, webRTCConnectionCountCookie } from "../Functions/cookie"
import { redirectToWebRTC } from "../Functions/redirects"

export function GrayBox(props) {
  const {
    displayName,
    sessionName,
    isMultiroom,
    isInvitePage,
    sessionEnded,
    showWebRTCComponents,
    isWebRTCSite,
    isClientSupported,
    showNudge,
    link
  } = props;

  const style = {
    parent: {
      display: "flex",
      flex: "1",
      flexDirection: "column",
      backgroundColor: isInvitePage ? "transparent" : "rgba(51, 51, 51, 0.9)",
      color: isInvitePage ? "#333333" : "#ffffff",
      alignItems: "stretch",
      padding: isInvitePage ? "0%" : "5vh",
      fontFamily: "Lato",
      width: "100%",
      maxWidth:'600px',
      borderRadius: '8px',
    } as React.CSSProperties,
    image: {
      marginBottom: '70px'
    },
    welcome: {
      fontStyle: "italic",
      textAlign: "center",
      fontSize: "3vh",
      marginTop: "40px",
      marginBottom: "10px",
      fontWeight: 300
    } as React.CSSProperties,
    title: {
      fontWeight: 700,
      textAlign: "center",
      fontSize: "4vh",
      wordBreak: "break-word"
    } as React.CSSProperties,
    link: {
      textDecoration: "underline",
      color: 'white',
    },
    install: {
      textAlign: "center",
    } as React.CSSProperties,
    buttonWrapper: {
      marginTop: '40px'
    },
    multi: {
      display: "flex",
      justifyContent: "center"
    }
  };

  let viewType;

  if (sessionEnded) {
    viewType = "session_ended";
  } else {
    if (isMultiroom) {
      viewType = "multiroom";
    } else {
      viewType = "default";
    }
  }
  const webRTCLinkHandler = () => {
    const webRTCCookie = webRTCConnectionCountCookie();
    const hideNudgeCookie = hideWebRTCNudgeCookie();
    const webrtcCountValue = webRTCCookie.get();
    const hideNudgeValue = hideNudgeCookie.get();

    if (!isInvitePage) {
      if (hideNudgeValue === true) {
        redirectToWebRTC(window.location);
      } else if (webrtcCountValue >= 4) {
        showNudge();
      } else {
        redirectToWebRTC(window.location);
      }
    }
  };

  return (
    <div style={style.parent} className="gray-box">
      {!isInvitePage && <Header style={style.image}/>}
      {isWebRTCSite && <InBrowserLaunch />}
      {isMultiroom || isInvitePage ? (
        <span style={style.multi}>
          <Trans i18nKey="quickconnect__multiRoomSessionTag">
            Multi-Room Session
          </Trans>
        </span>

      ) : null}
      {!isWebRTCSite && (
        <React.Fragment>
          {!sessionEnded ? (
            <div className="welcome-title" style={style.welcome}>
              <Trans i18nKey="quickconnect__welcomeTo">Welcome to</Trans>
            </div>
          ) : null}
          <div className="session-ended" style={style.title}>
            {viewType === "session_ended" && (
              <Trans i18nKey="quickconnect__sorrySessionEnded">
                Sorry, this Multi-Room session has ended
              </Trans>
            )}
            {viewType === "multiroom" && (
              <div className="session-name">{sessionName}</div>
            )}
            {viewType === "default" && (
              <div className="display-name">{displayName}</div>
            )}
          </div>
            <CallToActionContainer
              isWebRTCSupported={showWebRTCComponents}
              isClientSupported={isClientSupported}
            />
          {
            showWebRTCComponents &&
            isClientSupported &&
            !isInvitePage &&
            !isMultiroom && (
            <div style={style.install}>
              <Trans i18nKey="quickconnect__installApp">
                <strong><i>
                  Having trouble installing the app?<br></br>
                </i></strong>
                <a
                  className="localDownload-link"
                  href={link}
                  onClick={() => setTimeout(clientDownloaded, 0)}
                >
                  <u style={style.link} onMouseOver={() => "this.style.color = 'white'"}>Download the app directly</u>
                </a>
              </Trans>
            </div>
            )
          }
          {!sessionEnded && !showWebRTCComponents && <AlternativeContainer />}
        </React.Fragment>
      )}
    </div>
  );
}
