import { connect } from "react-redux";
import { clientDownloaded } from "../redux/action";

import { TranslatedCallToAction as CallToAction } from "../Elements/CallToAction";
import { determineLink } from "../Functions/determineLink";

const mapStateToProps = state => {
  return determineLink(state);
};

const mapDispatchToProps = dispatch => ({
  clientDownloaded: () => dispatch(clientDownloaded())
});

const CallToActionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallToAction);

export default CallToActionContainer;
