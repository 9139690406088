import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";

import Help from "../Elements/Help";
import Lookin from "../Elements/Lookin";
import Configure from "../Elements/Configure";
import "../Typography/font.css";

const Footer = props => {
  const {
    showConfigure,
    showLookin,
    helpLink,
    helpText,
    isInvitePage,
    isMultiroom,
    showWebRTCComponents
  } = props;

  const style = {
    container: {
      width: "100%",
      backgroundColor: "rgba(51, 51, 51, 0.9)",
      fontFamily: "Lato",
      fontSize: "2.5vh",
      whiteSpace: "nowrap",
      paddingLeft: "0",
      paddingRight: "0"
    },
    leftCol: {
      height: "8vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    rightCol: {
      height: "8vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end"
    }
  };

  return (
    <Container style={style.container} fluid={true}>
      {!showWebRTCComponents && (
        <Row className="row no-gutters">
          <Col className="col-6" style={style.leftCol}>
            <Help helpLink={helpLink} helpText={helpText} />
          </Col>
          <Col className="col-6" style={style.rightCol}>
            {showLookin && !isInvitePage && !isMultiroom ? <Lookin /> : null}
            {showConfigure && !isInvitePage && !isMultiroom ? (
              <Configure />
            ) : null}
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  showConfigure: state.model.SHOW_CONFIGURE_OPTION,
  showLookin: state.model.SHOW_LOOKIN_OPTION,
  helpLink:
    state.model.SERVER_QUESTIONS_ABOUT_PRODUCT_URL ||
    state.model.SERVER_QUESTIONS_ABOUT_PRODUCT_MULTI_ROOM_URL,
  helpText:
    state.model.SERVER_QUESTIONS_ABOUT_PRODUCT_URL_TEXT ||
    state.model.SERVER_QUESTIONS_ABOUT_PRODUCT_MULTI_ROOM_URL_TEXT
});

export default connect(mapStateToProps)(Footer);
