import React from "react";
import { PhoneInTalk } from "@material-ui/icons";
import { Trans, withTranslation } from "react-i18next";

import * as namespace from "../lang/namespace";
import IconTextHolder from "../Layout/IconTextHolder";
import "../Typography/font.css";

const AudioBridge = props => {
  const { info } = props;
  const style = {
    parent: {
      fontFamily: "Lato",
      marginTop: "1vh",
      marginBottom: "1vh"
    } as React.CSSProperties,
    title: {
      fontWeight: 700,
      fontSize: "2vh",
      display: "flex",
      alignItems: "center",
      flex: "1"
    } as React.CSSProperties,
    info: {
      fontWeight: 400,
      fontSize: "2vh"
    } as React.CSSProperties,
    icon: {
      color: "#66cc33"
    } as React.CSSProperties
  };

  return (
    <div style={style.parent}>
      <IconTextHolder>
        <PhoneInTalk />
        <div style={style.title}>
          <Trans i18nKey="quickconnect__audioBridgeDialIn">Dial in</Trans>
        </div>
      </IconTextHolder>
      <IconTextHolder>
        <div>&nbsp;</div>
        <div style={style.info}>{info}</div>
      </IconTextHolder>
    </div>
  );
};

export default withTranslation(namespace)(AudioBridge);
