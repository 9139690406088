import React from "react";
export function CodeInputDigit(props) {
  const { hasError, value, id, updateValue, registerInputRef } = props;

  const relayValueUpdate = updatedValue => {
    updateValue(id, updatedValue);
  };

  const onChange = event => {
    if (/[0-9]{1}/.test(event.target.value)) {
      const updatedValue = event.target.value;
      relayValueUpdate(updatedValue);
    }
  };

  const handleKeyDown = event => {
    if (event.key === "Backspace") {
      relayValueUpdate("");
    }
  };

  const style = {
    input: {
      height: "3.5rem",
      width: "2.25rem",
      margin: "0 0.5rem",
      textAlign: "center",
      fontSize: "2.375rem",
      lineHeight: "2.375rem",
      padding: "1rem 0",
      borderRadius: "4px",
      fontFamily: "Lato, sans-serif",
      border: hasError ? "1.5px solid red" : "none"
    } as React.CSSProperties
  };

  return (
    <input
      style={style.input}
      className="code-digit-input"
      maxLength={1}
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      ref={registerInputRef}
    />
  );
}
