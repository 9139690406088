import React from "react";
import { CodeInputDigit } from "./CodeInputDigit";

interface CodeInputProps {
  inputChanged: (value: string) => void;
  hasError: boolean;
  digits: number;
  finished: () => void;
}

interface CodeInputState {
  value: string;
  valuesMap: string[];
  refMap: HTMLInputElement[];
}

export class CodeInput extends React.Component<CodeInputProps, CodeInputState> {
  constructor(props) {
    super(props);

    const valuesMap = Array.from({ length: props.digits }).map(() => "");

    this.state = {
      value: "",
      valuesMap: valuesMap,
      refMap: []
    };
  }

  updateValue = () => {
    const newValue = this.state.valuesMap.join("");

    this.setState(
      {
        value: newValue
      },
      () => this.props.inputChanged(this.state.value)
    );
  };

  handleUpdateDigitValue = (idx, updatedValue) => {
    this.setState(
      {
        valuesMap: this.state.valuesMap.map((existingValue, index) =>
          index === idx ? updatedValue : existingValue
        )
      },
      () => {
        if (idx < this.state.refMap.length - 1) {
          this.state.refMap[idx + 1].focus();
        } else {
          this.props.finished();
        }

        this.updateValue();
      }
    );
  };

  registerInputRef = (ref: HTMLInputElement) => {
    const refCopy = ref;

    const newRefMap: HTMLInputElement[] = this.state.refMap;
    newRefMap.push(refCopy);

    this.setState(state => ({
      ...state,
      refMap: newRefMap
    }));
  };

  render() {
    const { hasError } = this.props;
    const { valuesMap } = this.state;

    return (
      <div>
        {valuesMap.map((val, idx) => (
          <CodeInputDigit
            id={idx}
            hasError={hasError}
            value={val}
            updateValue={this.handleUpdateDigitValue}
            registerInputRef={this.registerInputRef}
          />
        ))}
      </div>
    );
  }
}
