import React from "react";
import { RemoveRedEye } from "@material-ui/icons";
import { Trans, withTranslation } from "react-i18next";

import * as namespace from "../lang/namespace";

const Lookin = () => {
  const style = {
    parent: {
      textDecoration: "none",
      height: "8vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginRight: "1.5vh"
    } as React.CSSProperties,
    icon: {
      color: "#66cc33",
      marginLeft: "1.5vh",
      marginRight: "1.5vh"
    },
    text: {
      color: "#bababa"
    }
  };

  return (
    <a href="/Look-in" style={style.parent}>
      <RemoveRedEye style={style.icon} />
      <span style={style.text} className="hidden-sm-down lookin">
        <Trans i18nKey="quickconnect__footerBrowserLookIn">
          Browser Look-in
        </Trans>
      </span>
    </a>
  );
};

export default withTranslation(namespace)(Lookin);
