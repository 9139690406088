export const clientDownloaded = () => {
  return {
    type: "CLIENT_DOWNLOADED"
  };
};

export const clientConnected = () => {
  return {
    type: "CLIENT_CONNECTED"
  };
};

export const setModel = data => ({
  type: "SET_MODEL",
  data
});
