import React from "react";
import { StyleSheet, css } from "aphrodite/no-important";

import multiroom from "../Assets/multiroom.jpg";
import quickconnect from "../Assets/quickconnect.jpg";

class Background extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      background: new Image(),
      loaded: false
    };
  }

  componentWillMount() {
    // wait for component to receive real props
    // and load the correct background image.
    // once it's finished loading, show the div.
    // the background will fade in without the image
    // peeling as it loads on slow connections
    this.setBackground(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setBackground(nextProps);
  }

  setBackground(props) {
    const background = this.state.background;
    // set a loaded flag when the image has actually loaded
    background.onload = this.setState({ loaded: true });
    // if it's invite page, show nothing. multiroom show the "room" image, quickconnect devices on a table
    background.src = props.isInvitePage
      ? ""
      : props.isMultiroom
      ? multiroom
      : quickconnect;
    this.setState({ background });
  }

  render() {
    const { isMultiroom, isInvitePage } = this.props;
    const { background, loaded } = this.state;
    const opacity = isInvitePage ? "0" : isMultiroom ? "0.5" : "0.3";

    const style = StyleSheet.create({
      parent: {
        backgroundColor: isInvitePage ? "#ffffff" : "#333333",
        height: "100%"
      },
      background: {
        animationName: {
          from: {
            opacity: "0"
          },
          to: {
            opacity: "1"
          }
        },
        animationDuration: "2000ms",
        backgroundImage: `url(${background.src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100%"
      },
      overlay: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: `rgba(0, 0, 0, ${opacity})`
      },
      children: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        "z-index": "10"
      }
    });

    return (
      <div className={css(style.parent)}>
        {loaded ? <div className={css(style.background)} /> : null}
        <div className={css(style.overlay)} />
        <div className={css(style.children)}>{this.props.children}</div>
      </div>
    );
  }
}

export default Background;
