import { connect } from "react-redux";
import InviteHolder from "../Elements/InviteHolder";

const mapStateToProps = state => {
  return {
    isInvitePage: state.model.isInvitePage,
    sessionEnded: state.model.isInvitePage
      ? state.model.statusCode === "sessionIsNotActive"
      : state.model.SESSION_IS_ACTIVE_ON_LOAD && !state.model.sessionIsActive
  };
};

const InviteHolderContainer = connect(mapStateToProps)(InviteHolder);

export default InviteHolderContainer;
