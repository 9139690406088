import React from "react";

import "./App.css";

import DataLoaderContainer from "./Containers/DataLoaderContainer";

const App = () => {
  return <DataLoaderContainer />;
};

export default App;
