import { createStore, compose } from "redux";
import { persistStore, autoRehydrate } from "redux-persist";

import rootReducer from "./reducer";

declare var module;

const configureStore = () => {
  const composeEnhancers =
    window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
  const store = createStore(rootReducer, composeEnhancers(autoRehydrate()));

  const persistOptions = {
    whitelist: ["storage"]
  };
  persistStore(store, persistOptions);

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("./reducer", () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
};

export default configureStore;
