import React from "react";
import solsticeWhite from "../Assets/WebConfig.png";
import { Container } from "reactstrap";

const Header = props => {
  const { isInvitePage } = props;
  const style = {
    container: {
      width: "100%",
      backgroundColor: isInvitePage ? "rgba(51, 51, 51, 0.9)" : "",
      display: "flex",
      justifyContent: "center"
    },
    image: {
      padding: ".25vh",
      maxHeight: "8vh"
    }
  };

  return (
    <Container style={style.container}>
      <img src={solsticeWhite} style={style.image} alt="" />
    </Container>
  );
};

export default Header;
