import i18next from "i18next";
import axios from "axios";

export function fetchInitialData(initialUrl, isInvitePage, inviteKey) {
  let url = initialUrl;
  // if it's an invite page - our url needs the id, otherwise nothing
  url = isInvitePage ? (url += inviteKey) : url;

  return new Promise((resolve, reject) => {
    return axios(url)
      .then(res => {
        const json = res.data;
        // i18n / l10n
        const lang = json.lang.replace("_", "-");
        i18next.changeLanguage(lang, (err, t) => {
          if (err) {
            const errMsg = `Quickconnect - error changing language ${lang}: `;
            console.log(errMsg, err);
            reject(errMsg);
          }
          // save data to store
          resolve(json);
        });
      })
      .catch(err => {
        const errMsg = `error fetching initial data: ${err}`;
        console.log(errMsg, err);
        reject(errMsg);
      });
  });
}
