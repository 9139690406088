import { combineReducers } from "redux";

const initialState = {
  DISPLAY_NAME_HERE: "",
  SESSION_NAME: "",
  IDENTIFY_ID: "",
  AUTO_CONNECT_LINK: "",
  ANDROID_STORE_LINK: "",
  IOS_STORE_LINK: "",
  // xp not supported as of 3.1
  // 'WINXP_CLIENT_DOWNLOAD_LINK': '',
  WIN_CLIENT_DOWNLOAD_LINK: "",
  MAC_CLIENT_DOWNLOAD_LINK: "",
  SERVER_QUESTIONS_ABOUT_PRODUCT_URL: "",
  SERVER_QUESTIONS_ABOUT_PRODUCT_URL_TEXT: "",
  SHOW_CONFIGURE_OPTION: true,
  SHOW_LOOKIN_OPTION: true,
  SESSION_IS_ACTIVE_ON_LOAD: false,
  identifyIdIsOnServer: false,
  sessionName: "",
  isInvitePage: false,
  audioBridgeTitle1: "",
  audioBridgeData1: "",
  SessionDisplays: [
    {
      SESSION_DISPLAY_NAME: "",
      Users: []
    }
  ],
  downloadLink: null
};

const model = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MODEL":
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const storage = (state = {}, action) => {
  switch (action.type) {
    case "CLIENT_DOWNLOADED":
      return Object.assign({}, state, {
        clientHasBeenDownloadedBefore: true
      });
    case "CLIENT_CONNECTED":
      return Object.assign({}, state, {
        clientHasConnectedBefore: true
      });
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  model,
  storage
});

export default rootReducer;
