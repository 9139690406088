import React from "react";
import { Settings } from "@material-ui/icons";

const Lookin = () => {
  const style = {
    parent: {
      textDecoration: "none",
      height: "8vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    } as React.CSSProperties,
    icon: {
      color: "#bababa",
      marginLeft: "1.5vh",
      marginRight: "1.5vh"
    } as React.CSSProperties,
    line: {
      backgroundColor: "#bababa",
      width: "0.2vw",
      height: "6vh"
    } as React.CSSProperties,
    link: {
      display: "flex",
      flexDirection: "column"
    } as React.CSSProperties
  };

  return (
    <div style={style.parent}>
      <a href="/Config/config.html" style={style.link}>
        <Settings style={style.icon} href="/Config/config.html" />
      </a>
    </div>
  );
};

export default Lookin;
