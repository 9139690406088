export function getWebRTCUrl(location: Location): string {
  return `https://${location.hostname}:6443`;
}

export function getQuickConnectUrl(location: Location): string {
  return `http://${location.hostname}`;
}

export function isWebRTCSite(location: Location): boolean {
  return location.port === "6443";
}
