import { connect } from "react-redux";

import AudioBridge from "../Elements/AudioBridge";

const mapStateToProps = state => {
  const result = {
    info: state.model.audioBridgeData1
  };
  return result;
};

const AudioBridgeContainer = connect(mapStateToProps)(AudioBridge);

export default AudioBridgeContainer;
