import {
  hasAppSelector,
  appLinkSelector,
  isMultiRoomSelector,
  sessionEndedSelector,
  isInvitePageSelector
} from "../redux/selectors";

export function determineLink(state) {
  const hasApp = hasAppSelector(state);
  const link = appLinkSelector(state);
  const isMultiRoom = isMultiRoomSelector(state);
  const sessionEnded = sessionEndedSelector(state);
  const isInvitePage = isInvitePageSelector(state);

  return {
    link,
    sessionEnded,
    hasApp,
    isMultiRoom,
    isInvitePage
  };
}
