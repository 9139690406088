import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { GrayBox } from "./GrayBox";
import * as namespace from "../lang/namespace";
import * as platform from "platform";
import { isWebRTCSite } from "../Functions/urls";
import { webRTCSupported } from "../Functions/webRTCSupported";
import { determineLink } from "../Functions/determineLink";

const mapStateToProps = (state, props) => ({
  displayName: state.model.DISPLAY_NAME_HERE,
  sessionName: state.model.sessionName,
  isMultiroom: state.model.SESSION_IS_ACTIVE_ON_LOAD,
  isInvitePage: state.model.isInvitePage,
  sessionEnded: state.model.isInvitePage
    ? state.model.statusCode === "sessionIsNotActive"
    : state.model.SESSION_IS_ACTIVE_ON_LOAD && !state.model.sessionIsActive,
  isWebRTCSite: isWebRTCSite(window.location),
  showWebRTCComponents: webRTCSupported(platform) && state.model.WEBRTC_ENABLED,
  showNudge: props.showNudge,
  link: determineLink(state).link
});

export default connect(mapStateToProps)(withTranslation(namespace)(GrayBox));
