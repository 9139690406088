import React from "react";
import { Help } from "@material-ui/icons";
import { Trans, withTranslation } from "react-i18next";

import * as namespace from "../lang/namespace";

const HelpElement = props => {
  const { helpLink, helpText } = props;

  const style = {
    parent: {
      textDecoration: "none",
      height: "8vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    } as React.CSSProperties,
    icon: {
      color: "#66cc33",
      marginLeft: "1.5vh",
      marginRight: "1.5vh"
    },
    text: {
      color: "#bababa"
    },
    link: {
      color: "#66cc33"
    }
  };

  return (
    <div>
      <a className="help-link" href={helpLink} style={style.parent} target="_blank">
        <Help style={style.icon} />
        <span style={style.text}>
          <Trans i18nKey="quickconnect__footerNeedHelp">Need help? Visit </Trans>
        </span>
        &nbsp;
        <span style={style.link}>{helpText}</span>
      </a>
    </div>
  );
};

export default withTranslation(namespace)(HelpElement);
