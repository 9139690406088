import React from "react";
import { Trans } from "react-i18next";

import { redirectToWebRTC } from "../Functions/redirects";
import { hideWebRTCNudgeCookie } from "../Functions/cookie";
import { appLinkSelector } from "../redux/selectors";
import { connect } from "react-redux";

type ClientNudgeProps = {
  link: string;

  hideNudge: () => void;
};

function ClientNudgeComponent(props: ClientNudgeProps) {
  const styles = {
    wrapper: {
      padding: "24px",
      backgroundColor: "#393939",
      border: "1px solid #979797",
      borderRadius: "4px",
      color: "white",
      fontFamily: "Lato"
    } as React.CSSProperties,
    header: {
      fontSize: "40px",
      lineHeight: "48px",
      fontStyle: "italic",
      fontWeight: "bold",
      letterSpacing: "-0.41px",
      marginBottom: "16px"
    } as React.CSSProperties,
    text: {
      fontSize: "24px",
      lineHeight: "29px",
      fontWeight: "normal",
      letterSpacing: "-0.24px",
      margin: 0
    } as React.CSSProperties,
    subHeader: {
      fontSize: "24px",
      lineHeight: "29px",
      fontStyle: "italic",
      fontWeight: 500,
      letterSpacing: "-0.24px",
      marginTop: "24px",
      marginBottom: "16px"
    } as React.CSSProperties,
    downloadLink: {
      color: "#3399CC",
      fontStyle: "italic"
    } as React.CSSProperties,
    continueLink: {
      color: "#3399CC"
    } as React.CSSProperties,
    dismissLink: {
      color: "#ffffff"
    } as React.CSSProperties,
    linkWrapper: {
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "-0.16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "48px"
    } as React.CSSProperties,
    featureList: {
      margin: 0,
      padding: 0,
      listStyleType: "none",
      marginBottom: "48px",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "-0.2px"
    } as React.CSSProperties
  };

  const continueClickHandler = () => {
    redirectToWebRTC(window.location);
  };

  const dismissHandler = () => {
    hideWebRTCNudgeCookie().set(true);

    props.hideNudge();
  };

  return (
    <div className="client-nudge" style={styles.wrapper}>
      <h1 className="header" style={styles.header}>
        <Trans i18nKey="quickconnect__cn_feelingsAreHurt">
          You're missing out!
        </Trans>
      </h1>
      <p className="text" style={styles.text}>
        <Trans i18nKey="quickconnect__cn_bargaining">
          We noticed you have been using the app-free Solstice experience, and we
          just wanted to let you know what you're missing out on by not
          downloading the full app today!
        </Trans>
      </p>
      <p className="subheader" style={styles.subHeader}>
        <Trans i18nKey="quickconnect__cn_fullFeatures">
          Full App Features:
        </Trans>
      </p>
      <ul className="feature-list" style={styles.featureList}>
        <li>
          <Trans i18nKey="quickconnect__cn_liMedia">
            - Media file share
          </Trans>
        </li> 
        <li>
          <Trans i18nKey="quickconnect__cn_liContent">
            - Multi content sharing
          </Trans>
        </li>
        <li>
          <Trans i18nKey="quickconnect__cn_liOrg">
            - Content organization
          </Trans>
        </li>
        <li>
          <Trans i18nKey="quickconnect__cn_liWhat">
            - Moderator mode
          </Trans>
        </li>
        <li>
          <Trans i18nKey="quickconnect__cn_liAudio">
            - Desktop audio support
          </Trans>
        </li>
      </ul>
      <p className="text" style={styles.text}>
        <Trans i18nKey="quickconnect__cn_begDownload">
          <a
            className="download-link"
            href={props.link}
            style={styles.downloadLink}
          >
              Download now
          </a>
          <span>&nbsp;</span>to get the full Solstice experience!
        </Trans>
      </p>
      <div className="link-wrapper" style={styles.linkWrapper}>
        <a
          className="dismiss-link"
          href="javascript:void(0)"
          style={styles.dismissLink}
          onClick={dismissHandler}
        >
          <Trans i18nKey="quickconnect__cn_pleaseStop">
            Don't show this again
          </Trans>
        </a>
        <a
          className="continue-link"
          href="javascript:void(0)"
          style={styles.continueLink}
          onClick={continueClickHandler}
        >
          <Trans i18nKey="quickconnect__cn_ignoreUs">
            Continue to launch in browser &gt;
          </Trans>
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  link: appLinkSelector(state)
});

export const ClientNudge = connect(mapStateToProps)(ClientNudgeComponent);
