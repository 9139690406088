import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { setupi18n } from "./lang/i18n";

import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import configureStore from "./redux/configureStore";

declare var module;

setupi18n();

const root = document.getElementById("app");
const store = configureStore();

render(
  <Provider store={store}>
    <App />
  </Provider>,
  root
);

if (module.hot) {
  module.hot.accept("./App", () => {
    render(
      <Provider store={store}>
        <App />
      </Provider>,
      root
    );
  });
}
