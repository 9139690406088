import { webRTCConnectionCountCookie, hideWebRTCNudgeCookie } from "./cookie";

export function redirectToWebRTC(location: Location): void {
  const webRTCCookie = webRTCConnectionCountCookie();
  const hideNudgeCookie = hideWebRTCNudgeCookie();

  const webRTCConnectCount = webRTCCookie.get();
  const hideNudge = hideNudgeCookie.get(false);

  if (webRTCConnectCount < 4 && hideNudge !== true) {
    webRTCCookie.set(webRTCConnectCount + 1);
  }

  const { hostname } = location;
  window.location.assign(`https://${hostname}:6443/`);
}
