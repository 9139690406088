export function hasAppSelector(state): boolean {
  return state.storage.clientHasBeenDownloadedBefore &&
    state.storage.clientHasConnectedBefore
    ? true
    : false;
}

export function appLinkSelector(state): string {
  return (hasAppSelector(state)
    ? state.model.AUTO_CONNECT_LINK
    : state.model[state.model.downloadLink]) as string;
}

export function isMultiRoomSelector(state): string {
  return state.model.SESSION_IS_ACTIVE_ON_LOAD ? "Join" : "Connect";
}

export function sessionEndedSelector(state): boolean {
  return state.model.isInvitePage
    ? state.model.statusCode === "sessionIsNotActive"
    : state.model.SESSION_IS_ACTIVE_ON_LOAD && !state.model.sessionIsActive;
}

export function isInvitePageSelector(state): boolean {
  return state.model.isInvitePage;
}
