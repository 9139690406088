import React from "react";
import { Helmet } from "react-helmet";

const HeadManager = props => {
  const { isMultiroom, isInvitePage } = props;
  let title = `Solstice Quick Connect`;
  title = isMultiroom ? (title += " - Multi Room Session") : title;
  title = isInvitePage ? (title += " Invite") : title;

  return (
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
      />
      <title>{title}</title>
    </Helmet>
  );
};

export default HeadManager;
