import { connect } from "react-redux";
import Alternative from "../Elements/Alternative";

const determineLink = state => {
  const hasApp =
    state.storage.clientHasBeenDownloadedBefore &&
    state.storage.clientHasConnectedBefore
      ? true
      : false;

  const link = hasApp
    ? state.model[state.model.downloadLink]
    : state.model.AUTO_CONNECT_LINK;

  return {
    link,
    hasApp
  };
};

const mapStateToProps = state => {
  const linkDetails = determineLink(state);
  return {
    link: linkDetails.link,
    hasApp: linkDetails.hasApp
  };
};

const AlternativeContainer = connect(mapStateToProps)(Alternative);

export default AlternativeContainer;
