import React from "react";
import { Row, Col } from "reactstrap";

import Pod from "./Pod";

const PodHolder = props => {
  const { pods = [] } = props;
  const style = {
    marginTop: "1.5vh",
    marginBottom: "1.5vh"
  };

  return (
    <div style={style}>
      {pods.map(pod => (
        <Row className="no-gutters" key={pod.name}>
          <Col>
            <Pod pod={pod} />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default PodHolder;
