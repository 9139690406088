import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import * as namespace from "./namespace";
import ar_SA from "../lang/ar_SA.json";
import cy_GB from "../lang/cy_GB.json";
import da_DK from "../lang/da_DK.json";
import de_DE from "../lang/de_DE.json";
import en_US from "../lang/en_US.json";
import es_ES from "../lang/es_ES.json";
import fr_FR from "../lang/fr_FR.json";
import it_IT from "../lang/it_IT.json";
import ja_JP from "../lang/ja_JP.json";
import ko_KR from "../lang/ko_KR.json";
import nb_NO from "../lang/nb_NO.json";
import pl_PL from "../lang/pl_PL.json";
import pt_PT from "../lang/pt_PT.json";
import ru_RU from "../lang/ru_RU.json";
import sv_SE from "../lang/sv_SE.json";
import tr_TR from "../lang/tr_TR.json";
import zh_CN from "../lang/zh_CN.json";
import zh_TW from "../lang/zh_TW.json";

export function setupi18n() {
  // languages to be loaded eagerly
  const resources = {
    "ar-SA": {
      [namespace]: ar_SA
    },
    "cy-GB": {
      [namespace]: cy_GB
    },
    "da-DK": {
      [namespace]: da_DK
    },
    "de-DE": {
      [namespace]: de_DE
    },
    "en-US": {
      [namespace]: en_US
    },
    "es-ES": {
      [namespace]: es_ES
    },
    "fr-FR": {
      [namespace]: fr_FR
    },
    "it-IT": {
      [namespace]: it_IT
    },
    "ja-JP": {
      [namespace]: ja_JP
    },
    "ko-KR": {
      [namespace]: ko_KR
    },
    "nb-NO": {
      [namespace]: nb_NO
    },
    "pl-PL": {
      [namespace]: pl_PL
    },
    "pt-PT": {
      [namespace]: pt_PT
    },
    "ru-RU": {
      [namespace]: ru_RU
    },
    "sv-SE": {
      [namespace]: sv_SE
    },
    "tr-TR": {
      [namespace]: tr_TR
    },
    "zh-CN": {
      [namespace]: zh_CN
    },
    "zh-TW": {
      [namespace]: zh_TW
    }
  };

  i18n.use(initReactI18next).init(
    {
      fallbackLng: "en-US",
      ns: [namespace],
      defaultNS: namespace,
      preload: [
        "ar-SA",
        "da-DK",
        "de-DE",
        "en-US",
        "es-ES",
        "fr-FR",
        "it-IT",
        "ja-JP",
        "ko-KR",
        "nb-NO",
        "pl-PL",
        "pt-PT",
        "ru-RU",
        "sv-SE",
        "tr-TR",
        "zh-CN",
        "zh-TW"
      ],
      // log info level to console
      debug: false,
      interpolation: {
        // https://www.i18next.com/interpolation.html
        escapeValue: false
      },
      initImmediate: false,
      keySeparator: false, // default is '.'
      react: {
        // wait for translations before rendering to avoid flashes of
        // untranslated content
        defaultTransParent: "span",
        wait: true,
        bindI18n: "languageChanged loaded" // rerender on these events
      },
      resources // load resources above - we just switch language dynamically
    },
    (err, t) => {
      if (err)
        return console.log(
          `Quickconnect - i18n init failed: ${JSON.stringify(err)}`
        );
    }
  );

  i18n.on("missingKey", function(lngs, namespace, key, res) {
    console.log(`missing key for ${lngs}: ${key}`);
  });
}

export default i18n;
