export const QuickConnectCookies = {
  WEBRTC_CONNECTION_COUNT: "WEBRTC_CONNECTION_COUNT",
  HIDE_WEBRTC_NUDGE: "HIDE_WEBRTC_NUDGE"
};

type Cookie = {
  key: string;
  value?: string;
};

type CookieSetterGetter<T> = {
  get: (defaultValue?: T) => T;
  set: (value: T) => void;
};

/**
 * @name webRTCConnectionCountCookie
 * @function
 * @description Used to get or set the value of the 'WEBRTC_CONNECTION_COUNT' cookie
 * @returns {CookieSetterGetter<number>} An object containing getter and setter functions for this particular cookie
 */
export function webRTCConnectionCountCookie(): CookieSetterGetter<number> {
  return {
    get: (defaultValue: number = 0) => {
      const cookie = getCookieValue(
        QuickConnectCookies.WEBRTC_CONNECTION_COUNT
      );

      if (cookie === null || cookie === undefined) {
        if (defaultValue !== null && defaultValue !== undefined) {
          updateCookie(
            QuickConnectCookies.WEBRTC_CONNECTION_COUNT,
            defaultValue.toString()
          );
          return defaultValue;
        } else {
          return null;
        }
      } else {
        return parseInt(cookie.value);
      }
    },
    set: (value: number) => {
      updateCookie(
        QuickConnectCookies.WEBRTC_CONNECTION_COUNT,
        value.toString()
      );
    }
  };
}

/**
 * @name hideWebRTCNudgeCookie
 * @function
 * @description Used to get or set the value of the 'HIDE_WEBRTC_NUDGE' cookie
 * @returns {CookieSetterGetter<boolean>} An object containing getter and setter functions for this particular cookie
 */
export function hideWebRTCNudgeCookie(): CookieSetterGetter<boolean> {
  return {
    get: (defaultValue: boolean = false) => {
      const cookie = getCookieValue(QuickConnectCookies.HIDE_WEBRTC_NUDGE);

      if (cookie === null || cookie === undefined) {
        if (defaultValue !== null && defaultValue !== undefined) {
          updateCookie(
            QuickConnectCookies.HIDE_WEBRTC_NUDGE,
            defaultValue.toString()
          );

          return defaultValue;
        } else {
          return null;
        }
      } else {
        return cookie.value === "true" ? true : false;
      }
    },
    set: (value: boolean) => {
      updateCookie(QuickConnectCookies.HIDE_WEBRTC_NUDGE, value.toString());
    }
  };
}

/**
 * @name getCookieValue
 * @function
 * @param {string} keyName the key of the cookie to be fetched
 */
function getCookieValue(keyName: string): Cookie {
  const rawCookie = document.cookie;

  const cookies: Cookie[] = rawCookie.split(";").map(cookie => {
    if (cookie.indexOf("=") !== -1) {
      const kvp = cookie.split("=");
      return {
        key: kvp[0].trim(),
        value: kvp[1].trim()
      };
    } else {
      return {
        key: cookie.trim()
      };
    }
  });

  return cookies.find(x => x.key === keyName);
}

/**
 * @name updateCookie
 * @function
 * @param {string} key key of the cookie
 * @param {string} value value of the cookie
 * @returns {void}
 */
function updateCookie(key: string, value: string) {
  const cookie = buildCookie(key, value);

  document.cookie = cookie;
}

/**
 * @name buildCookie
 * @description Builds the cookie string that is stored in document.cookie
 * @param {string} key cookie key name
 * @param {string} value cookie value
 * @param {string} expiration expiration time
 * @param {string} path path cookie parameter
 * @returns {string}
 */
function buildCookie(
  key: string,
  value?: string,
  expiration?: string,
  path?: string
): string {
  let cookieBody = key;

  if (value) {
    cookieBody += `=${value}`;
  }

  if (path) {
    cookieBody += `;path=${path}`;
  }

  if (expiration) {
    cookieBody += `;expires=${expiration}`;
  }

  return cookieBody;
}
