import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import platform from "platform";
import q from "q";

import Background from "../Layout/Background";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Content from "../Layout/Content";
import HeadManager from "../Elements/HeadManager";

import config from "../config";
import { clientConnected, setModel } from "../redux/action";
import { fetchInitialData } from "../Functions/fetchInitialData";
import { isWebRTCSite } from "../Functions/urls";
import { clientIsSupported, isWindows32OS } from "../Functions/webRTCSupported";

interface DataLoaderProps {
  isInvitePage: boolean;
  setModel: (model: any) => void;
  identifyId: string;
  clientConnected: () => void;
  isWebRTCEnabled: boolean;
  isHttpsRedirect: boolean;
  dnsName: string;
}

interface DataLoaderState {
  inviteKey: string;
}

class DataLoader extends React.Component<DataLoaderProps, DataLoaderState> {
  private timer = null;
  private initialUrl = null;
  private pollUrl = null;

  constructor(props) {
    super(props);
    this.poll = this.poll.bind(this);
    this.determinePlatform = this.determinePlatform.bind(this);
    this.determineInvitePage = this.determineInvitePage.bind(this);
    this.state = {
      inviteKey: null
    };
  }

  componentWillMount() {
    // determine if they're Mac/Windows/iOS/Android
    this.determinePlatform();

    if (!isWebRTCSite(window.location)) {
      // call this first to figure out what type of page to render

      this.determineInvitePage()
        .then(() =>
          fetchInitialData(
            this.initialUrl,
            this.props.isInvitePage,
            this.state.inviteKey
          )
        )
        .then(model => this.props.setModel(model))
        .then(() => {
          if (this.props.isHttpsRedirect && window.location.protocol !== 'https:') {
            this.attemptHttpsRedirect();
          }
        })
        .then(() => this.poll());
    } else {
      // Set model if it needs to be set
      fetchInitialData(
        `https://${window.location.hostname}/connect/init`,
        false,
        ""
      )
      .then(model => this.props.setModel(model))
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  determineInvitePage() {
    // setState is async so we need a promise
    const deferred = q.defer();
    // invite page url will contain the word 'session'
    const uniqueString = "session/invite_";
    const sessionIndex = window.location.href.indexOf(uniqueString);
    const isInvitePage = sessionIndex > 0 ? true : false;

    // if we're on the invite page, fetch from a different set of API endpoints
    // which are defined in config
    this.initialUrl = isInvitePage ? config.inviteInitial : config.qcInitial;
    this.pollUrl = isInvitePage ? config.invitePoll : config.qcPoll;

    if (isInvitePage) {
      const inviteKey = window.location.href.substring(
        sessionIndex + uniqueString.length
      );
      // dispatch the action to save the inviteKey in the store
      const data = {
        inviteKey,
        isInvitePage: true
      };

      this.setState(
        state => ({
          ...state,
          ...data
        }),
        () => deferred.resolve()
      );
      this.props.setModel(data);
    } else {
      // resolve we're not waiting on anything
      deferred.resolve();
    }

    return deferred.promise;
  }

  determinePlatform() {
    const family = platform.os.family;
    
    let downloadLink;

    switch (true) {
      case family.indexOf("XP") !== -1:
      case family.indexOf("Windows") !== -1:
        const isWin32 = isWindows32OS(family, platform.os.architecture);
        if (isWin32) downloadLink = "unknown"; // we don't include win32 installers anymore
        else downloadLink = "WIN_CLIENT_DOWNLOAD_LINK";
        break;
      case family.indexOf("iOS") !== -1:
        downloadLink = "IOS_STORE_LINK";
        break;
      case family.indexOf("OS X") !== -1:
        downloadLink = "MAC_CLIENT_DOWNLOAD_LINK";
        break;
      case family.indexOf("Android") !== -1:
        downloadLink = "ANDROID_STORE_LINK";
        break;
      default:
        downloadLink = "unknown"; // we can't figure out their os -  unsupported
    }

    this.props.setModel({ downloadLink });
  }

  poll() {
    // if it's an invite page, the suffix is of the form '3DA22625-62DA-6A6B-4A92-607EE80D0952'
    // if it's a regular quickconnect/multiroom page, the suffix is of the form '2882539351'
    const suffix = this.props.isInvitePage
      ? this.state.inviteKey
      : this.props.identifyId;
    const url = `${this.pollUrl}${suffix}`;
    axios(url)
      .then(res => {
        const json = res.data;
        // round trip complete, mark the client as connected in local storage
        if (json.identifyIdIsOnServer === true) this.props.clientConnected();
        this.props.setModel(json);
      })
      .catch(err => console.log(`Quickconnect - error polling data: ${err}`));

    this.timer = setTimeout(this.poll, 5000);
  }

  render() {
    const { isInvitePage } = this.props;

    const showWebRTCComponents = isWebRTCSite(window.location);

    return (
      <div>
        <Background {...this.props}>
          <HeadManager {...this.props} />
          {isInvitePage && <Header isInvitePage={true} />}
          <Content {...this.props} />
          <Footer {...this.props} showWebRTCComponents={showWebRTCComponents} />
        </Background>
      </div>
    );
  }

  attemptHttpsRedirect() {
    fetch('https://' + this.props.dnsName).then(response => {
      if (response.ok) {
        window.location.replace("https://" + this.props.dnsName + window.location.pathname);
      }
    }).catch( e => {
      console.log(e);
    })
  }
}

const mapStateToProps = state => ({
  isMultiroom: state.model.SESSION_IS_ACTIVE_ON_LOAD,
  isInvitePage: state.model.isInvitePage,
  isWebRTCEnabled: state.model.WEBRTC_ENABLED,
  isClientSupported: clientIsSupported(platform.os.family),
  isHttpsRedirect: state.model.HTTPS_REDIRECT,
  dnsName: state.model.DNS_NAME
});

const mapDispatchToProps = dispatch => ({
  clientConnected: () => dispatch(clientConnected()),
  setModel: data => dispatch(setModel(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DataLoader);
